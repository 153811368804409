import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
 export class SearchServiceService {
  apiRoot:string = 'https://api.pwnedpasswords.com/range/';
  results:Object[];
  loading:boolean;

  constructor(private http:HttpClient) {
    this.results = [];
    this.loading = false;
    
  }

  search(rango:string) {
    let apiURL = `https://api.pwnedpasswords.com/range/${rango}`;
      return fetch(apiURL)
      .then(res => res.text())
      .then(data => data.toString().split("\r"));
    }
  
}
