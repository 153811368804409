import { Component, OnInit } from '@angular/core';
import CryptoJS  from 'crypto-js';
import { SearchServiceService } from '../search-service.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  password="";
  range="";
  numberViolation=0;
  visibleCorrecto="none";
  visibleMaldito="none";
  visibleNoValido="none";
  
  getValue(val:string){
    this.password=val;
    console.log(val);
  }

  constructor(private search: SearchServiceService) { }

  ngOnInit(){
  }

  async encriptarContrasena(){

    const hashObject=CryptoJS.SHA1(this.password);
    const hashPass=hashObject.toString(CryptoJS.enc.Hex).toUpperCase();

    if(this.password==""){
      this.visibleNoValido="block";
      this.visibleMaldito="none";
      this.visibleCorrecto="none";
    }
    this.range=hashPass.substring(0,5);

    let map = new Map();
    let response = await this.search.search(this.range);

    response.map(row => this.range.concat(row))
    .map(concat=> concat.replace('\n', ''))
    .forEach(hashNumber=> map.set(hashNumber.split(":")[0].toUpperCase(),hashNumber.split(":")[1]))

    this.numberViolation=map.get(hashPass);
    console.log(this.numberViolation);

    if(this.numberViolation==undefined){
      this.visibleMaldito="none";
      this.visibleNoValido="none";
      this.visibleCorrecto="block";
    }else{
      this.visibleCorrecto="none";
      this.visibleNoValido="none";
      this.visibleMaldito="block";
    }

  } 
}
